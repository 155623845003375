<template>
  <div class="v-stack h-stretch gap-3" v-if="item">
    <h1 class="heading-title-1">Info</h1>
    <div class="h-stack h-end">
      <button
        class="edit"
        @click="$router.push('/pricelist/item/edit/' + item._id)"
      >
        Edit
      </button>
    </div>
    <div class="card light mn">
      <div class="content">
        <div class="v-stack gap-3">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Name:</label>
            <div class="text-left">
              {{ item.name }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Category:</label>
            <div class="text-left">
              {{ item.categoryMain }} - {{ item.categorySub }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Unit:</label>
            <div class="text-left">
              {{ item.unit }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Comment:</label>
            <div class="text-left">
              {{ item.comment }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Editable Price:</label>
            <div class="text-left">
              {{ item.editablePrice ? "Yes" : "No" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Editable Count:</label>
            <div class="text-left">
              {{ item.editableCount ? "Yes" : "No" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Owned:</label>
            <div class="text-left">
              {{ item.inHouse ? "Yes" : "No" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="item.inHouse && item.categoryMain == 'TECHNIKA'">
      <template v-if="$store.state.accessLevel >= 9">
        <h1 class="heading-title-1">Gear</h1>
        <div class="h-stack h-end">
          <button
            class="add"
            @click="$router.push('/pricelist/gear/add/' + item._id)"
          >
            Add
          </button>
        </div>
        <Table
          :items="item.gear"
          v-on:rowSelected="$router.push('/pricelist/gear/edit/' + $event._id)"
          :columns="[
            {
              name: 'Date of Purchase',
              sort: 'date',
              path: ['dateOfPurchase'],
              formatType: 'date',
              format: 'DD.MM.YYYY',
            },
            {
              name: 'Serial Number',
              sort: 'alphabet',
              path: ['serialNumber'],
            },
            {
              name: 'Store Description',
              sort: 'alphabet',
              path: ['storeDescription'],
            },
            {
              name: 'Cost',
              sort: 'numeric',
              path: ['value'],
              format: 'czk',
              formatType: 'currency',
            },
            {
              name: 'Description',
              sort: 'alphabet',
              path: ['description'],
            },
          ]"
        ></Table>
      </template>
      <template v-if="$store.state.accessLevel > 9">
        <h1 class="heading-title-1">Revenue</h1>
        <div class="card light">
          <div class="content">
            <div class="v-stack gap-3">
              <div class="pane-horizontal gap-3">
                <div class="text-right">Cost:</div>
                <div class="text-left">{{ format.currency(totalCost) }} Kč</div>
              </div>
              <div class="pane-horizontal gap-3">
                <div class="text-right">Revenue:</div>
                <div class="text-left">
                  {{ format.currency(totalRevenue) }} Kč
                </div>
              </div>
              <div class="pane-horizontal gap-3" style="font-weight: bold">
                <div class="text-right">Balance:</div>
                <div class="text-left">
                  {{ format.currency(totalBalance) }} Kč
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table
          :items="gearInBudget"
          :css="'80px 1fr 1fr 60px 100px 100px'"
          :columns="[
            {
              name: 'Date',
              sort: 'date',
              path: ['project', 'deadline'],
              formatType: 'date',
              format: 'DD.MM.YYYY',
            },
            {
              name: 'Project',
              sort: 'alphabet',
              path: ['project', 'name'],
            },
            {
              name: 'Pricelist',
              sort: 'alphabet',
              path: ['pricelist', 'name'],
            },
            {
              name: 'Count',
              sort: 'numeric',
              path: ['item', 'count'],
            },
            {
              name: 'Price',
              sort: 'numeric',
              path: ['item', 'price'],
              format: 'czk',
              formatType: 'currency',
            },
            {
              name: 'Total',
              sort: 'numeric',
              path: ['item', 'totalPrice'],
              format: 'czk',
              formatType: 'currency',
              formatMap: (price) => Math.round(price),
            },
          ]"
        >
        </Table>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "@/components/Table.vue";
import utils from "@/utils.js";

export default {
  props: ["id"],
  data() {
    return {
      item: null,
      gearInBudget: [],
      format: utils.format,
    };
  },
  components: {
    Table,
  },
  computed: {
    totalRevenue() {
      let sum = 0;
      for (const gear of this.gearInBudget) {
        sum += gear.item.count * gear.item.price;
      }
      return sum;
    },
    totalBalance() {
      return this.totalRevenue - this.totalCost;
    },
    totalCost() {
      let sum = 0;
      if (this.item) {
        for (const item of this.item.gear) {
          sum += item.value;
        }
      }
      return sum;
    },
  },
  methods: {
    ...mapActions(["getPricelistItem", "getProjects"]),
  },
  mounted() {
    this.getPricelistItem(this.id)
      .then((item) => {
        this.item = item;
      })
      .catch((error) => {
        console.log(error);
      });

    this.getProjects()
      .then((projects) => {
        this.gearInBudget = [];

        for (const project of projects) {
          for (const pricelist of project.selectedPricelists) {
            for (const item of pricelist.items) {
              if (item.item._id == this.id) {
                item.totalPrice =
                  item.count *
                  item.price *
                  ((100 - pricelist.discountPercent) / 100);
                this.gearInBudget.push({
                  project: project,
                  pricelist: pricelist,
                  item: item,
                });
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
label,
div {
  align-self: center;
}
</style>
